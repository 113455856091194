<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="View Customer Prospek" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Proyek</label>
            <vs-input class="w-full" v-model="data.nama_proyek" readonly/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Staf Marketing *</label>
            <vs-input class="w-full" v-model="data.nama_staf" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Kode Prospek *</label>
            <vs-input class="w-full" v-model="data.kode" readonly/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tgl. Prospek *</label>
            <vs-input class="w-full" v-model="data.tgl" readonly/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Status *</label>
            <vs-input class="w-full" v-model="data.status" readonly/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Sumber Info *</label>
            <vs-input class="w-full" v-model="data.nama_sumber_info" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/1 w-full">
            <label class="ml-1 text-xs">Keterangan *</label>
            <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.keterangan" disabled/>
          </div>
        </div>

        <div class="vx-row mb-3"></div>
        <vs-divider> Detail Prospek Customer </vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" label="Kode Customer *" v-model="data.customer.kode" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-user" label="Nama *" v-model="data.customer.nama" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-smartphone" label="HP *" v-model="data.customer.hp" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Profesi</label>
            <vs-input class="w-full" v-model="data.nama_profesi" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Jenis Kelamin</label>
            <vs-input class="w-full" :value="data.gender === 'L' ? 'Laki - Laki' : 'Perempuan'" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-mail" label="E-mail" v-model="data.customer.email" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" label="Kota *" v-model="data.customer.kota" readonly/>
          </div>
          <div class="vx-col sm:w-8/12 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label="Alamat" v-model="data.customer.alamat" readonly/>
          </div>
        </div>

      </div>

    </vs-popup>
  </div>
</template>

<script>
import 'flatpickr/dist/flatpickr.css'
import _ from 'lodash'

export default {
  name: 'CustomerProspekEdit',
  props: ['isActive', 'item'],
  components: {
  },
  watch: {
    isActive (value) {
      if (value === true) {
        this.data = Object.assign({}, this.item)

        if (this.isFirstActive) {
          this.isFirstActive = false
        }
      }
    }
  },
  data () {
    return {
      isFirstActive: true,
      isLoading: false,
      errors: null,
      isModalStafActive: false,
      isModalCustomerActive: false,
      data: {
        customer: {
          gender: 'L'
        },
        id_proyek: null
      }
    }
  },
  methods: {

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
